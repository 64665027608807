import { Alert, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, TextField, Typography } from "@mui/material";
import useGlobal from "./useGlobal";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";





export function LoginDialog({open}) {
    const [axios,setAxios] = useGlobal('axios')
    const [jwt,setJWT] = useGlobal('jwt')
    const [permissions,setPermissions] = useGlobal('permissions',[])
    const [budget,setBudget] = useGlobal('budget',-1)
    const [gname,setGname] = useGlobal('gname');
    const [username,setUsername] = useState('');
    const [password,setPassword] = useState('');
    const [mistry,setMistry] = useState(0)
    const [remindme,setRemindme] = useState(false)
    const [waitingstuff,setWaitingstuff] = useGlobal('waitingstuff',false)
    const [globalinfo,setGlobalinfo] = useGlobal('globalinfo',false)
    const [wrongcred,setWrongcred] = useState(false)
    const currentlocation = useLocation()



    // useEffect(() => {
    //     console.log('Logindialog'.axios,open)
    //     if (open) {
    //       axios.post('login',{'username':'Dohmen, Christoph','password':'Behindert$1',}).then(resp =>{
    //         if (resp.status == 200){
    //           console.log('set jwt')
    //         setJWT(resp.data.jwt)
    //         setPermissions(resp.data.permissions)}
    //         setBudget(resp.data.budget)
    //         setGname(resp.data.username)
    //       }
    //     )
    //     }
    // },[axios,open])


    useEffect(() => {
        setGname(username)
    },[username])
         
    const handleEnter = (e) => {
        if (e.key === 'Enter') {handleLogin()}
    }

    const handleLogin = () => {
      setMistry(mistry+1)
      axios.post('login',{'username':username,'password':password,}).then(resp =>{
          console.log('p',resp)
          if (resp.status == 200){
            console.log('set jwt')
          setJWT(resp.data.jwt)
          setGname(resp.data.username)
          setPermissions(resp.data.permissions)
          setBudget(resp.data.budget)
          setWaitingstuff(resp.data.waitingstuff)
          setGlobalinfo(resp.data.balkeninfo)
          setWrongcred(false)
          } else {
            setWrongcred(resp.response.data.msg)
          }
        }
      ).catch(resp => {
        console.log('e',resp)
        setWrongcred(resp.response.data.msg)
      })
    }

    return (
      <>
        <Dialog open={open} PaperProps={{style:{padding:'20px'},className:'userinfodialog'}} sx={{backdropFilter:'blur(10px)'}}>
            <DialogTitle>Login
            </DialogTitle>
            <DialogContent style={{padding:'25px'}}>
        <TextField label="Benutzername"  value={username} onChange={e => setUsername(e.target.value)}/><br/>
        <TextField label="Passwort" value={password} type='password' style={{marginTop:'5px'}}
                    onChange={e => setPassword(e.target.value)} onKeyPress={e => handleEnter(e)}/>
                    <br/>
          <Typography variant="caption" sx={{color:'red'}}>Aldente-Login</Typography>
          {/* <FormControlLabel  label='angemeldet bleiben' control={<Checkbox checked={!remindme} onClick={e=> setRemindme(!remindme)}/>} style={{marginTop:'5px',paddingLeft:'20px'}} /> */}
            </DialogContent>
        {!!wrongcred && <Alert severity="warning">{wrongcred}</Alert> }
            <DialogActions>
                <Button variant="contained" color="primary" primary="true" onClick={(event) => handleLogin()}>Anmelden</Button>
            </DialogActions>
        </Dialog>
        
      </>
    )
}