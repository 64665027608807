import { Alert, Box, Button, Container, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import useGlobal from "./useGlobal";
import MaterialTable from "material-table";
import { Pool } from "@mui/icons-material";



export function BestellEingang({status}){
    const [axios,setAxios] = useGlobal('axios')
    const [permissions,setPermissions] = useGlobal('permissions',[])
    const [bestellungen,setBestellungen] = useState([])
    const [bfilter,setBfilter] = useState([])
    const [activeFilter,setActiveFilter] = useState(false)
    const [checkedrows,setCheckedrows] = useState([])
    const [fullrows,setFullrows] = useState(false)
    const [lieferanten,setLieferanten] = useState([])



    useEffect(() => {
        getData()
    },[])

    const getData = () => {
        const params = {status:status,fullset:true}
        axios.get('bestellungen',{params}).then(result => {
            setActiveFilter(false)
            setBestellungen(result.data)
            setLieferanten([...new Set(result.data.map(item => item.lieferant))])
        })
    }

    useEffect(() => {
        setBfilter(bestellungen)
    },[bestellungen])

    const expandChange = (e,n) => {
        setFullrows(n)
    }

    const selectionChange = (rows) => {
        setCheckedrows(rows.filter(r => r._id).map(r => r._id))
    }

    const toggleFilter = (l) => {
        if (l) {
            setBfilter(bestellungen.filter(b => b.lieferant == l))
        } else {setBfilter(bestellungen)}
        setActiveFilter(l)
    }

    const changeStatus = (newstatus) => {
        axios.post('statuschange',{ids:checkedrows,newstatus:newstatus}).then(result => getData())
    }

    const takefrompool = (row) => {
        console.log(row)
        axios.post('auspoolliefern',{row:row}).then(result => getData())
    }


    return (
        <Container sx={{minHeight:'50vh'}}>
        <Container>
        {!!lieferanten.length && 
            <>
            <Typography variant="h5">Filter nach Lieferant</Typography>
            {lieferanten.map(l => 
                <Button key={'button_'+l} onClick={e => toggleFilter(l)} variant={l == activeFilter ? 'contained' : 'outlined'}>{l}</Button>
            )}
             <Button key={'button_clear'} onClick={e => toggleFilter(false)} variant={'outlined'}>Filter löschen</Button>
            </>
        }
        </Container>
            {!!bfilter.length && <MaterialTable
                title="Bestellungen"
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} von {count}',
                        labelRowsPerPage: 'Zeilen pro Seite',
                        labelRowsSelect : 'Zeilen'
                    },
                    toolbar: {
                        nRowsSelected: '{0} Reihen ausgewählt',
                        searchPlaceholder: 'Suche'
                    },
                    header: {
                        actions: 'Aktionen'
                    },
                    
                    body: {
                        emptyDataSourceMessage: 'Keine Daten zum Anzeigen',
                        editRow : {
                            deleteText : 'Sicher löschen?'
                        }
                    }
                }}
                columns={[
                    { title: 'Produkt', field: 'produkt', },
                    { title: 'Variante', field: 'variante',render: rowData => rowData.variante ? rowData.variante : '' },
                    { title: 'Lieferant', field: 'lieferant',hidden:fullrows },

                    { title: 'Anzahl', field:'anzahl', render: rowData => rowData?.anzahl ? rowData.anzahl : 1},
                    { title: 'Lagernd', field:'poolitems', render: rowData =>rowData?.poolitems && rowData?.poolitems.length ? rowData?.poolitems.length: ''},
                    { title: 'aus Pool bedienen', field:'poolitems', hidden: !fullrows, 
                        render: rowData => rowData?.poolitems && rowData?.poolitems.length && rowData._id && (rowData?.user !== 'POOL') ? <Pool onClick={e => takefrompool(rowData)}/> : ''},
                    {title:'User',field:'user',hidden:!fullrows},
                    {title:'Bestelldatum',field:'bestelldatum',hidden:!fullrows}
                ]}

                options={{
                    selection: true,
                    exportButton: true,
                    pageSize: 10,
                    exportFileName: 'Bestellung',
                    
                }}
                onSelectionChange={(rows) => selectionChange(rows)}
                data={bfilter}
                parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                onTreeExpandChange={(e,v) => expandChange(e,v)}
                
            />}
        <Box sx={{margin: '10px'}}>
        <Button variant="contained" disabled={!checkedrows.length} onClick={e => changeStatus(status+1)}>{status == 0 ? 'markierte Einträge als bestellt markieren' : 'markierte Einträge als eingegangen markieren'}</Button>
        </Box>
        </Container>
    )
}


export function Abholung({}){
    const [axios,setAxios] = useGlobal('axios')
    const [permissions,setPermissions] = useGlobal('permissions',[])
    const [bestellungen,setBestellungen] = useState([])
    const [bfilter,setBfilter] = useState([])
    const [checkedrows,setCheckedrows] = useState([])
    const [fullrows,setFullrows] = useState(false)
    const [search,setSearch] = useState('')
    const [abholkeys,setAbholkeys] = useState([])
    const [nohit, setNohit] = useState([])
    const [multinames,setMultinames] = useState([])
    const [inactiveuser,setInactiveuser] = useState([])


    const findIDs = (inputString) => {
        const idPattern = /\b[A-Z0-9]{5}\b/g;
        const matches = inputString.match(idPattern);
        return matches ? matches : [];
    }

    useEffect(() => {
        //getData()
    },[])

    useEffect(() => {
        const params = {fullset:true,keys:abholkeys}
        axios.get('abholungen',{params}).then(result => {
            setBestellungen(result.data.liste)
            setNohit(result.data.nohit || [])
            setMultinames(result.data.taker || [])
            setInactiveuser(result.data.inactive)
        })
    },[abholkeys])

    const getData = () => {
        const params = {fullset:true}
         axios.get('abholungen',{params}).then(result => {
            setBestellungen(result.data.liste)
        })
    }

    useEffect(() => {
        setBfilter(bestellungen)
    },[bestellungen])

    const expandChange = (e,n) => {
        setFullrows(n)
    }

    const selectionChange = (rows) => {
        setCheckedrows(rows.filter(r => r._id).map(r => r._id))
    }


    const changeStatus = (newstatus) => {
        axios.post('statuschange',{ids:checkedrows,newstatus:newstatus,taker:false}).then(result => getData())
    }

    const keysame = (arr1,arr2) => {
        if (arr1.length !== arr2.length) {
            return false;
        }
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) {
                return false;
            }
        }
        return true;
    }

    const searchinput = (text) => {
        const newids = findIDs(text)
        if (!keysame(abholkeys,newids))
        setAbholkeys(findIDs(text))
        setSearch(text)
    }

    const handout = () => {
        const newstatus = 3
        axios.post('statuschange',{ids:checkedrows,newstatus:newstatus,abholkeys:abholkeys,abholer:multinames[0]}).then(result => {
            getData()
            setSearch('')
            setAbholkeys([])
            setNohit([])
            setMultinames([])
            setInactiveuser([])
        })
    }


    return (
        <Container sx={{minHeight:'50vh'}}>
            <TextField fullWidth value={search} label='Abholkeys' onChange={e => searchinput(e.target.value.toUpperCase())}/>
            {!!abholkeys.length &&
             <Alert severity="info" variant="filled" >
                 <>{abholkeys.length} gültige Abholkeys erkannt: <b>{abholkeys.join(', ')}</b></>
            </Alert>           
            
            }
            {!!nohit.length &&
            <Alert severity="warning" variant="filled" >
                <>Kein Treffer für: <b>{nohit.join(', ')}</b></>
            </Alert>
                }
            {!!multinames.length && multinames.length > 1 &&
            <Alert severity="error" variant="filled">
                <Box>Multible Abholer: <b>{multinames.map(n => <Box>{n}</Box>)}</b></Box>
                <Box>Ausbuchung so nicht möglich (fehlende Empfängerinfo)</Box>
            </Alert>
            }
            {!!inactiveuser.length && inactiveuser.length > 1 &&
            <Alert severity="error" variant="filled">
                <Box>Gesperrte User: <b>{inactiveuser.map(n => <Box>{n}</Box>)}</b></Box>
                <Box>Bitte vor Ausgabe Status klären</Box>
            </Alert>
            }
            {!!bestellungen.length && <MaterialTable
                title="Ausgabe"
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} von {count}',
                        labelRowsPerPage: 'Zeilen pro Seite',
                        labelRowsSelect : 'Zeilen'
                    },
                    toolbar: {
                        nRowsSelected: '{0} Reihen ausgewählt',
                        searchPlaceholder: 'Suche'
                    },
                    header: {
                        actions: 'Aktionen'
                    },
                    
                    body: {
                        emptyDataSourceMessage: 'Keine Daten zum Anzeigen',
                        editRow : {
                            deleteText : 'Sicher löschen?'
                        }
                    }
                }}
                columns={[
                    { title: 'Produkt', field: 'produkt' },
                    { title: 'Variante', field:'variante',render: rowData => rowData.variante ? rowData.variante : ''},
                    { title: 'Anzahl', field:'anzahl', render: rowData => rowData?.anzahl? rowData.anzahl : 1},
                    { title: 'Lager', field:'lagernummer', render: rowData => rowData?.lagernummer ? rowData.lagernummer : ''},
                    { title: 'Für Mitarbeiter', field: 'user', hidden:!fullrows },
                    { title: 'Abholkey', field: 'abholkey', hidden:!fullrows, 
                            render: rowData => abholkeys.includes(rowData?.altkey?.abholkey) ? rowData.altkey.abholkey : rowData.abholkey },
                    { title: 'Abholung durch', field: 'abholkey', hidden:!fullrows,
                           render: rowData =>  abholkeys.includes(rowData?.altkey?.abholkey) ? rowData.altkey.user : rowData.user },
                    {title:'Bestelldatum',field:'bestelldatum',hidden:!fullrows}
                ]}
                options={{
                    selection: true,
                    search : false,
                    exportButton: true,
                    exportFileName: 'Ausgabe_'+abholkeys.join('_'),
                    pageSize: 10,
                }}
                onSelectionChange={(rows) => selectionChange(rows)}
                data={bestellungen}
                parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                onTreeExpandChange={(e,v) => expandChange(e,v)}
            />}
        <Box sx={{margin: '10px'}}>
            <Button variant="contained" disabled={multinames.length !== 1} onClick={e => handout()}>Ware ausgeben an {multinames[0]}</Button>
        </Box>
        </Container>
    )


    return (
        <>

            {!!bfilter.length && <MaterialTable
                title="Bestellungen"
                columns={[
                    { title: 'User', field: 'user' },
                    { title: 'Anzahl', field:'anzahl'},
                    {title: 'Abholkey',field:'abholkey'},
                    { title: 'Produkt', field: 'produkt', hidden:!fullrows },
                    { title: 'Variante', field: 'variante', hidden:!fullrows },
                    {title:'Bestelldatum',field:'bestelldatum',hidden:!fullrows}
                ]}
                options={{
                    selection: true
                }}
                onSelectionChange={(rows) => selectionChange(rows)}
                data={bfilter}
                parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                onTreeExpandChange={(e,v) => expandChange(e,v)}
            />}
        <Button variant="contained" disabled={!checkedrows.length} onClick={e => changeStatus(3)}>markierte Einträge als abgeholt markieren</Button>
        </>
    )
}