

export function uppername(input) {
    try {
        return input.split(', ').map(word => 
            word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        ).join(', ');
    } catch (error) {
        return input;
    }
}