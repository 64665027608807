import useStore from './Store'; // Pfad zu deiner Store-Datei

function useGlobal(key,initialValue) {
    const stateValue = useStore(state => state.states[key]);

    // Falls kein Wert vorhanden ist, verwende den Initialwert
    if (stateValue === undefined && initialValue !== undefined) {
      useStore.getState().setState(key, initialValue, initialValue);
    }
  
    const setValue = newValue => {
      useStore.getState().setState(key, newValue);
    };
    //console.log({[key]:stateValue})
    return [stateValue, setValue];
}

export default useGlobal;