import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, Typography, useScrollTrigger } from "@mui/material";
import useGlobal from "./useGlobal";
import { useEffect, useState } from "react";
import { Delete } from "@mui/icons-material";



export function Cart({showcart,closeme}) {
    const [cart,setCart] = useGlobal('cart',[])
    const [axios,setAxios] = useGlobal('axios')
    const [foruser,setForuser] = useGlobal('foruser',false)
    const [budget,setBudget] = useGlobal('budget',-1)
    const [curbudget,setCurbudget] = useGlobal('curbudget',0)
    const [bestellt,setBestellt] = useState(false)
    useEffect(()=> {
        if (cart && cart.length > 0) {
        setCurbudget(budget- cart.reduce((sum, item) => sum + parseFloat(item.Preis), 0))
            if (bestellt) {
                setBestellt(false)
            }
        }
        else {
            setCurbudget(budget)
        }
    },[cart,budget])

    const removeItem = (index) => {
        let tmpcart = cart
        tmpcart.splice(index,1)
        console.log(tmpcart)
        setCart([...tmpcart])
        console.log(cart)
    }

    const sendOrder = () => {
        axios.post('orderitems',{items:cart,foruser:foruser}).then(resp => {
            if (resp.status == 200) {
                const params = {foruser:foruser}
                axios.get('mybudget',{params}).then(response => setBudget(response.data))
                setCart([])                
                setBestellt(true)
            }
        })

    }

    return (
        <Dialog open={showcart} onClose={e => closeme(true)}>
            {bestellt ? 
            <>
                <DialogTitle>Bestellung aufgenommen</DialogTitle>
                <DialogContent>

                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={e => closeme(true)}>schließen</Button>
                </DialogActions>
            </>
            :
            <>
                <DialogTitle>Dein Einkaufswagen</DialogTitle>
                <DialogContent>
                    {cart?.length ?
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Artikel</TableCell>
                                <TableCell>Preis</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {cart.map((item,i) => 
                            <TableRow key={'cartitem_'+i}>
                                <TableCell>
                                    {item.name} {item?.variante && '('+item.variante+')' }
                                </TableCell>
                                <TableCell>
                                    {!!item?.Preis && parseFloat(item.Preis).toFixed(2)}€
                                </TableCell>
                                <TableCell>
                                    <Delete onClick={e => removeItem(i)}/>
                                </TableCell>
                            </TableRow>
                            )}
                        </TableBody>

                    </Table>                    
                    :
                    <Typography variant="h6">ist leer.</Typography>
                    }
                <box>Dein Restbudget: {parseFloat(curbudget).toFixed(2)}€</box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={e => closeme(true)}>abbrechen</Button>
                    <Button variant="outlined" onClick={e => setCart([])} disabled={!cart.length}>Liste leeren</Button>
                    <Button variant="contained" onClick={e => sendOrder()} disabled={!cart.length}>bestellen</Button>
                </DialogActions>
            </>
            }
        </Dialog>
    )

}
