import { Autocomplete, Box, Button, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Stack, TextField, Typography } from "@mui/material";
import useGlobal from "./useGlobal";
import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import './edititem.css'




export function UpdateItem({item,closeme}) {
    const [axios,setAxios] = useGlobal('axios')
    const [edit,Setedit] = useState(false)
    const [lieferanten,setLieferanten] = useState([])
    const [cats,setCats] = useState([])

    useEffect(() => {
        if (item){
            Setedit({...item,varianten:item?.varianten ? item.varianten.join(',') : ''})
            axios.get('lieferantenliste').then(data => setLieferanten(data.data))
            axios.get('cats').then(data => {setCats(data.data)})
        }
        else {Setedit(false)}
        console.log('i',item)
    },[item])

    useEffect(() => {console.log(edit)},[edit])


    const uploadFile = (file) => {
        const formData = new FormData();
        formData.append('file',file,file.name)
        axios.post('uploadImage',formData).then(result => {
            if (result.data.status === 200) {
                Setedit({...edit,bild:result.data.filename})
            }
        })
    }

    const updateProduct = () => {
        axios.post('updateItem',edit).then(result => 
            {
                if (result.status === 200) {
                    closeme(true)
            }
        })
    }

    const deleteProduct = () => {
        Swal.fire({
            title: 'Bist du sicher?',
            text: "Möchtest du dieses Produkt wirklich löschen?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ja, löschen!',
            cancelButtonText: 'Abbrechen',
            customClass: {
                container: 'swal2-container'
              }
          }).then(result =>
                {
                    if (result.isConfirmed) {
                    axios.post('deleteItem',edit).then(result => 
                        console.log('deleted')
                    )}
                    closeme(true)
                }
          )

    }
 

    return (
        <Dialog open={!!edit} onClose={e => closeme(false)}>
            {!!edit && 
            <>
                <DialogTitle>
                    {edit._id ? 
                    <Typography variant="h4">{edit.name}</Typography>
                    :
                    <TextField fullWidth value={edit.name} onChange={e => Setedit({...edit,name:e.target.value})} label='Name'/>
                    }
                </DialogTitle>
                <DialogContent>
                    <Container sx={{margin:'10px 0px 10px 0px'}}>
                        <img src={'https://shop.rdheinsberg.de/api/bild/big_'+edit.bild} alt='bild folgt' style={{width:'90%',height:'auto',minHeight:'40%'}}/>
                    </Container>
                    <Container style={{margin:'10px'}}>
                    <Button variant="contained" component="label">
                                    Bild hochladen
                                    <input type='file' hidden onChange={e => uploadFile(e.target.files[0])}/>
                     </Button>
                    </Container>                     
                    <Container>
                    <Stack spacing={2} >
                    <TextField multiline fullWidth value={edit.beschreibung} onChange={e => Setedit({...edit,beschreibung:e.target.value})} label='Beschreibung'/>
                    <TextField fullWidth value={edit?.varianten} onChange={e => Setedit({...edit,varianten:e.target.value})} label='Varianten' helperText='Komma-getrennt'/>
                    <Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:'10px'}}>
                        <TextField type='number' value={edit?.Preis} onChange={e => Setedit({...edit,Preis:e.target.value})} label="Preis"/>
                        <FormControlLabel control={<Checkbox/>} checked={edit?.relativpreis} onChange={e => Setedit({...edit,relativpreis:e.target.checked})} label='relativer Preis'/>
                        <TextField value={edit?.lagernummer} onChange={e => Setedit({...edit,lagernummer:e.target.value})}  label='Lagernummer'/>
                    </Box>
                    </Stack>
                    </Container>
                    <Container>

                    </Container>
                    <Container>
                            <Autocomplete freeSolo options={cats} value={edit?.kategorie || ''} sx={{marginTop:'10px'}}
                                    renderInput={(params) => <TextField {...params} label="Kategorie" helperText='Mit Enter Eingabe abschließen' />}
                                    onChange={(e,n) => {
                                        console.log(e,n)
                                    Setedit({...edit,kategorie:n})
                                    }
                                    }/>
                            <Box sx={{display:'flex',alignItems:'flex-start',marginTop:'10px'}}>
                            <Autocomplete freeSolo options={lieferanten} value={edit?.lieferant || ''} style={{width:'50%'}} 
                                    renderInput={(params) => <TextField {...params} label="Lieferant" style={{width:'100%'}}  helperText='Mit Enter Eingabe abschließen' />}
                                    onChange={(e,n) => Setedit({...edit,lieferant:n})} />
                            <TextField value={edit?.lieferant_prod_id} onChange={e => Setedit({...edit,lieferant_prod_id:e.target.value})}  style={{width:'50%'}} label='Prod. ID bei Lieferant'/>
                            </Box>
                    </Container>
                </DialogContent>
                <DialogActions>
                    <FormControlLabel control={<Checkbox/>} checked={edit.deaktiviert} onChange={e => Setedit({...edit,deaktiviert:e.target.checked})} label='deaktiviert'/>
                    <Button variant="outlined" onClick={e => deleteProduct()}>LÖSCHEN</Button>
                    <Button variant="outlined" onClick={e => closeme(false)}>abbrechen</Button>
                    <Button variant="contained" onClick={e => updateProduct()}>speichern</Button>
                </DialogActions>
            </>
            }
        </Dialog>
    )
}