import logo from './logo.svg';
import './shop.css';
import useGlobal from './useGlobal';
import { useEffect, useState } from 'react';
import Cookies from "js-cookie";
import { configure } from 'axios-hooks'
import Axios from 'axios'
import {NavLink, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import { LoginDialog } from './login';
import { Shop } from './shop';
import { Alert, AppBar, Autocomplete, Badge, Box, CircularProgress, Container, List, ListItem, ListItemButton, ListItemText,  SwipeableDrawer, TextField, Toolbar } from '@mui/material';
import { Menu, MiscellaneousServicesOutlined, ShoppingCart } from '@mui/icons-material';
import { uppername } from './jshelper';
import { Cart } from './cart';
import { OrderManagement } from './orders';
import { MyOrders } from './myorders';
import { isMobile } from 'react-device-detect';
import { makeStyles } from '@mui/styles';
import Swal from 'sweetalert2';


const instance = Axios.create({
  baseURL: 'https://shop.rdheinsberg.de/api/',
  withCredentials: true,
})




function App() {
  const [loggedin,setLoggedin] = useGlobal('loggedin',false)
  const [axios,setAxios] = useGlobal('axios',instance)
  const [jwt,setJWT] = useGlobal('jwt',false)
  const [gname,setGname] = useGlobal('gname','---')
  const [cart,setCart] = useGlobal('cart',[])
  const [budget,setBudget] = useGlobal('budget',-1)
  const [curbudget,setCurbudget] = useGlobal('curbudget',0)
  const [fulluserlist,setFulluserlist] = useState([])
  const [foruser,setForuser] = useGlobal('foruser',false)
  const [showcart,setShowcart] = useState(false)
  const [openmenu,setOpenmenu] = useState(false)
  const [loading,setLoading] = useState(false)
  const [waitingstuff,setWaitingstuff] = useGlobal('waitingstuff',false)
  const [globalinfo,setGlobalinfo] = useGlobal('globalinfo',false)

  const [permissions,setPermissions] = useGlobal('permissions',[])
  const currentlocation = useLocation()
  const navigate = useNavigate()



  useEffect(() => {
    if (isMobile){
      setJWT(sessionStorage.getItem('access_token') || Cookies.get('access_token'))
    } else {
      setJWT(sessionStorage.getItem('access_token'))
    }
    console.log(sessionStorage.getItem('access_token') || Cookies.get('access_token'))
    if (currentlocation.pathname == '/')
      navigate('shop')
  },[])

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!!cart.length) {
        const message = 'Es sind noch Produkte im Warenkorb. Warenkorb wird beim Schließen der Seite geleert.';
        event.returnValue = message; // Standard-Weg für die meisten modernen Browser
        return message; // Einige Browser verwenden dies
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup-Funktion zum Entfernen des Event-Listeners bei Unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [cart]);





  useEffect(() => {
    console.log('jwt set',jwt)
    if (jwt) {
      console.log('jwt',jwt)
      if (!Cookies.get('access_token') || (Cookies.get('access_token') !== jwt)) {
        console.log('set access_token')
        if (isMobile) {Cookies.set('access_token',jwt,{ domain: '.rdheinsberg.de',expires: 7,path: '/', secure: true });}
        sessionStorage.setItem('access_token',jwt)
      }
      instance.interceptors.request.use( config => { 
        config.headers['Authorization'] = 'Bearer '+jwt; 
        setLoading(true)
        return config;
      },
        error => {return Promise.reject(error);}
      );
      instance.interceptors.response.use( 
        response => {
          setLoading(false)
          return response
        },
        error => {
          setLoading(false)
          return Promise.reject(error)
        }
      )
      setAxios(instance)
      axios.get('mydata').then(response => {
        setGname(response.data.username)
        setPermissions(response.data.permissions)
        setBudget(response.data.budget)
        setWaitingstuff(response.data.waitingstuff)
        setGlobalinfo(response.data.balkeninfo)

      })
      setLoggedin(true)
      // if(!gname){
      //   axios.get('myusername').then(response => setGname(response.data))
      // }
      // if (!permissions) {
      //     axios.get('mypermissions').then(response => setPermissions(response.data))
      // }
      // if (!budget || budget < 0) {
      //   axios.get('mybudget').then(response => setBudget(response.data))
      // }
    }
    else {
      setLoggedin(false)
    }
  },[jwt])


  useEffect(() => {
    if (permissions && permissions.includes("shopmanager")) {
      axios.get('fulluserlist').then(response => {
        setFulluserlist([...response.data,'POOL'])
        //console.log(response.data)
      })
    }
  },[permissions])

  useEffect(() => {
    if (foruser) {
      console.log('foruser',foruser)
      const params = {foruser:foruser}
      axios.get('mybudget',{params}).then(response => setBudget(response.data)).catch(e => console.log(e))
    }
    console.log(currentlocation.pathname)
  },[foruser])


  const logout = () => {
    sessionStorage.removeItem('access_token')
    Cookies.remove('access_token')
    setLoggedin(false)
  }

  const navto = (target) => {
    navigate(target)
    setOpenmenu(false)
  }


  return (
    <div className="App">
      <AppBar position="fixed" >
        <Toolbar sx={{display:'flex',justifyContent:'space-between'}}>          
          <Menu onClick={e=> setOpenmenu(!openmenu)}/>
          <Box sx={{width:'50%',margin:'7px',display:'flex',alignItems:'center',justifyContent:'space-around'}}>
            {/* {uppername(gname)} | <span className={cart && cart.length ? 'budgetgrey' : 'budgetwhite'}>{parseFloat(curbudget).toFixed(2)}€</span> */}
            {(!!permissions && permissions.includes('shopmanager') && fulluserlist) ? 
                <>
                <Autocomplete options={fulluserlist} value={foruser || gname} style={{width:'50%'}} 
                renderInput={(params) => <TextField {...params} label="Für User" syle={{width:'100%',marginTop:'5px'}}/>}
                onChange={(e,n) => setForuser(n)} />
                <Box sx={{fontSize:'xx-large'}} className={cart && cart.length ? 'budgetgrey' : 'budgetwhite'}>{parseFloat(curbudget).toFixed(2)}€</Box>
                </>
              :
              <>
                {uppername(gname)}  <span className={cart && cart.length ? 'budgetgrey' : 'budgetwhite'}>{parseFloat(curbudget).toFixed(2)}€</span>
              </>
            }
          </Box>
          <Badge badgeContent={!!cart && cart.length} color='error'  invisible={!cart}>
            <ShoppingCart onClick={e => setShowcart(true)}/>
          </Badge>
        </Toolbar>
      </AppBar>
      <Container className='inhalt'>
      {!!(waitingstuff && currentlocation.pathname == '/shop') && 
      <Alert variant="filled" severity="success" className='Alert' onClick={e => navigate('myorders')}>
        <>Teile deiner Bestellungen liegen zur Abholung bereit. Dein Abholcode: <span style={{color:'yellow',fontSize:'larger'}}>{waitingstuff}</span></>
      </Alert>
      }
      {!!(globalinfo) && 
      <Alert variant="filled" severity="warning" className="Info" >
        {globalinfo}
      </Alert>
      }      
     {axios && loggedin  && 
      <Routes>
          <Route path="shop" element={<Shop/>}/>
          <Route path='orders' element={<OrderManagement/>}/>
          <Route path='myorders' element={<MyOrders/>}/>
      </Routes>
      } 
</Container>
     <SwipeableDrawer open={openmenu} onClose={e => setOpenmenu(false)} onOpen={e => setOpenmenu(true)} 
     PaperProps={{style: { backgroundColor: '#ffffffbf',},}}>
      <List>
      <ListItem >
            <ListItemButton>
              <ListItemText primary='Shop' onClick={e => navto('shop')}/>
            </ListItemButton>
          </ListItem> 
        {!!permissions && permissions.includes('shopoperator') &&
          <ListItem >
            <ListItemButton>
              <ListItemText primary='Verwaltung' onClick={e => navto('orders')}/>
            </ListItemButton>
          </ListItem>
        }
          <ListItem >
            <ListItemButton>
              <ListItemText primary='Meine Bestellungen' onClick={e => navto('myorders')}/>
            </ListItemButton>
          </ListItem>        
        <ListItem >
          <ListItemButton>
            <ListItemText primary='Logout' onClick={e => logout()}/>
          </ListItemButton>
        </ListItem>      
      
      </List>
      </SwipeableDrawer>
     <Cart showcart={showcart} closeme={e => setShowcart(!e)}/>
    <LoginDialog open={!loggedin}/>
    {loading && 
      <Box className='centered-box'>
          <CircularProgress />
      </Box>}
    </div>
  );
}

export default App;
