import {create} from 'zustand';

const useStore = create(set => ({
  states: {},
  // Erweiterter setState, der nur setzt, wenn noch kein Wert vorhanden ist
  setState: (key, value, initialValue) => set(state => ({
    states: {
      ...state.states,
      [key]: key in state.states ? value : initialValue 
    }
  })),
  getState: (key) => set.getState().states[key]
}));

export default useStore;

  
