import { Autocomplete, Avatar, Box, Container, Divider, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReplayOutlined } from "@mui/icons-material";
import useGlobal from "./useGlobal";
import './shop.css'


export function MyOrders() {
    const [axios,setAxios] = useGlobal('axios')
    const [bestellungen,setBestellungen] = useState([])
    const [forother,setForother] = useState([])
    const [fulluserlist,setFulluserlist] = useState([])
    const [foruser,setForuser] = useGlobal('foruser',false)


    useEffect(() => {
        console.log('mybestellungen')
        axios.get('fulluserlist').then(response => {
            setFulluserlist(response.data)
        })
        getData()
    },[])

    useEffect(() => {getData()},[foruser])



    const getData = () => {
        const params = {foruser:foruser}
        axios.get('bestellungen',{params}).then(result => {
            setBestellungen(result.data.own)
            setForother(result.data.forother)
        })
    }


    const addAltAbholer = (key,user) => {
        console.log(key,user)
        axios.post('altkey',{abholkey:key,abholuser:user,foruser:foruser}).then(result => getData())
    }

    const  getUniqueValues = (array) => {
        const uval = [...new Set(array.map(item => item['altkey']['abholkey']))];
        return uval
      }

    return (
        <Container maxWidth='lg' className="mainblank">
            <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
            <Typography variant="body1">Abholung Di & Do 8-16 Uhr im Zentrallager KTW-Wache</Typography>
            <ReplayOutlined sx={{fontSize:'40px'}}onClick={e => getData()} />
            </Box>
        <Divider/>        
        {!!bestellungen?.filter(b => b.status === 2).length && <>
        <Typography variant="h5">Deine abholbereiten Bestellung</Typography>
        <Typography variant="caption">Abholkey:</Typography>
        <Box sx={{color:'red',fontWeight:'700',fontSize:'x-large',marginBottom:'10px'}}>{bestellungen.filter(b=> b.status === 2)[0].abholkey}</Box>
        <Autocomplete options={fulluserlist} value={bestellungen.filter(b=> b.status === 2)[0]?.altkey?.user || ''} style={{width:'50%'}} 
                renderInput={(params) => <TextField {...params} label="Abholung möglich durch" style={{width:'100%'}}/>}
                onChange={(e,n) => addAltAbholer(bestellungen.filter(b=> b.status === 2)[0].abholkey,n)} />
        <List>
            {bestellungen.filter(b=> b.status === 2).map(b => 
            <>
            <ListItem key={'li_'+b.produkt}>
                {b.bild &&
                    <ListItemAvatar>
                        <Avatar src={'https://shop.rdheinsberg.de/api/bild/small_'+b.bild} />
                    </ListItemAvatar>
                }
                <ListItemText primary={
                    <React.Fragment>
                        <Typography variant="h6">{b.produkt}</Typography>
                        <Typography>{b.variante}</Typography>

                    </React.Fragment>
                
                
                } 
                secondary={
                    <React.Fragment>
                        <Box>Bestellt am {b.bestelldatum}</Box>
                    </React.Fragment>
                    }/>
            </ListItem>
            <Divider variant="inset" component="li" />
            </>
            )}
        </List> 
        </>}                    
        <Divider sx={{marginBottom:'15px'}}/>
        {!!forother.length && <Typography variant="h5">Abholberechtigungen für Kollegen</Typography>}
        {!!forother.length && getUniqueValues(forother).map(key => 
            <>
                {forother.filter(b => b.altkey.abholkey == key)[0].abholer ? 
                <Box sx={{color:'red',fontWeight:'700',fontSize:'x-large'}}>Abgeholt</Box> : <>
                <Typography variant="caption">Abholkey:</Typography>
                <Box sx={{color:'red',fontWeight:'700',fontSize:'x-large'}}>{key}</Box>
                </>}
                <Box sx={{fontWeight:'500',fontSize:'small'}}>für {forother.filter(b => b.altkey.abholkey == key)[0].user}</Box>
                <List>
                {forother.filter(b => b.altkey.abholkey == key).map(b => 
                        <>

                                <>
                                    <ListItem key={'li_'+b.produkt}>
                                        {b.bild &&
                                            <ListItemAvatar>
                                                <Avatar src={'https://shop.rdheinsberg.de/api/bild/small_'+b.bild} />
                                            </ListItemAvatar>
                                        }
                                        <ListItemText primary={
                                            <React.Fragment>
                                                <Typography variant="h6">{b.produkt}</Typography>
                                                <Typography>{b.variante}</Typography>

                                            </React.Fragment>
                                        
                                        
                                        } 
                                        secondary={
                                            <React.Fragment>
                                                <Box>Bestellt am {b.bestelldatum}</Box>
                                            </React.Fragment>
                                            }/>
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                    </>

                        </>
                )}
                </List> 
            </>
        )}
        <Divider sx={{marginBottom:'15px'}}/>
        {!!bestellungen?.filter(b => b.status < 2).length && <>
        <Typography variant="h5">Bestellungen im Zulauf</Typography>
        <List>
            {bestellungen.filter(b=> b.status < 2).map(b => 
            <>
            <ListItem key={'li_'+b.produkt}>
                {b.bild &&
                    <ListItemAvatar>
                        <Avatar src={'https://shop.rdheinsberg.de/api/bild/small_'+b.bild} />
                    </ListItemAvatar>
                }
                <ListItemText primary={
                    <React.Fragment>
                        <Typography variant="h6">{b.produkt}</Typography>
                        <Typography>{b.variante}</Typography>

                    </React.Fragment>
                
                
                } 
                secondary={
                    <React.Fragment>
                        <Box>Bestellt am {b.bestelldatum}</Box>
                    </React.Fragment>
                    }/>
            </ListItem>
            <Divider variant="inset" component="li" />
            </>
            )}
        </List> 
        </>}
        <Divider sx={{marginBottom:'15px'}}/>    
        {!!bestellungen?.filter(b => b.status > 2).length && <>
        <Typography variant="h5">Historie</Typography>
        <List>
            {bestellungen.filter(b=> b.status > 2).map(b => 
            <>
            <ListItem key={'li_'+b.produkt}>
                {b.bild &&
                    <ListItemAvatar>
                        <Avatar src={'https://shop.rdheinsberg.de/api/bild/small_'+b.bild} />
                    </ListItemAvatar>
                }
                <ListItemText primary={
                    <React.Fragment>
                        <Typography variant="h6">{b.produkt}</Typography>
                        <Typography>{b.variante}</Typography>

                    </React.Fragment>
                
                
                } 
                secondary={
                    <React.Fragment>
                        <Box>Bestellt am {b.bestelldatum}</Box>
                        <Box>Abgeholt am {b.abgeholt} von {b.abholer}</Box>

                    </React.Fragment>
                    }/>
            </ListItem>
            <Divider variant="inset" component="li" />
            </>
            )}
        </List> 
        <Box sx={{display:'flex',justifyContent:'space-around'}}>
            <div>Die ausgegebenen Artikel sind Eigentum der RDHS</div>
        </Box>   
        </>}

        </Container>
    )
}