import { AppBar, Autocomplete, Box, Button, Card, CardContent, Checkbox, Container, Divider, FormControlLabel, Step, StepButton, Stepper, Tab, Tabs, TextField, Typography } from "@mui/material";
import {AddBox, Search, ChevronRight, Margin, Edit} from "@mui/icons-material";
import { useEffect, useState } from "react";
import useGlobal from "./useGlobal";
import MaterialTable from "material-table";
import { ThemeProvider } from '@mui/styles'
import { createTheme } from '@mui/material';
import { Abholung, BestellEingang } from "./orderprocess";
import './shop.css'
import { useAsyncError } from "react-router";
import Swal from "sweetalert2";


export function OrderManagement() {
    const [tabindex,setTabindex] = useState(1)





    return (
        <Container fixed maxWidth='lg' className='main'>
            <AppBar>
            <Tabs variant="fullWidth" value={tabindex} onChange={(e,n) => setTabindex(n)} indicatorColor="secondary" textColor="inherit">
                <Tab label='Bestellungen' value={1}/>
                <Tab label='Mitarbeiter' value={2}/>
                <Tab label='Rohdaten' value={3}/>
                <Tab label="Verwaltung" value={4}/>
            </Tabs>
            </AppBar>            

            <Divider/>
            <Container maxWidth='lg' sx={{minHeight:'40%'}}>
            {(tabindex == 1) && 
                <Bestellungen/>
            }
            {(tabindex == 2) && 
                <Mitarbeiter/>
            }
            {(tabindex == 3) && 
                <Rohdaten/>
            }
            {(tabindex == 4) && 
                <Verwaltung/>
            }            
            </Container>
        </Container>
    )

}



function Bestellungen() {
    const [axios,setAxios] = useGlobal('axios')
    const [permissions,setPermissions] = useGlobal('permissions',[])
    const [bestellungen,setBestellungen] = useState([])
    const [step,setStep] = useState(0)
    const [fullrows,setFullrows] = useState(false)


    const mytheme =  createTheme({
    });

 
 

    return (
        <Container className="inhalt">
            <ThemeProvider theme={mytheme}>
            <Stepper nonLinear activeStep={step}>
                <Step completed={false}>
                    <StepButton onClick={e => setStep(0)}>Bestelleingang</StepButton>
                </Step>
                <Step completed={false}>
                    <StepButton onClick={e => setStep(1)}>Wareneingang</StepButton>
                </Step>
                <Step completed={false}>
                    <StepButton onClick={e => setStep(2)}>Abholung</StepButton>
                </Step>                
            </Stepper>

            {step == 0 && <BestellEingang status={0}/>}
            {step == 1 && <BestellEingang status={1}/>}
            {step == 2 && <Abholung/>}



        </ThemeProvider>
        </Container>
    )
}


function Mitarbeiter() {
    const [axios,setAxios] = useGlobal('axios')
    const [maliste,setMaliste] = useState([])
    const [permissions,setPermissions] = useGlobal('permissions',[])


    useEffect(() => {
        getData()
    },[])

    const getData = () => {
        axios.get('maliste').then(result => setMaliste(result.data))
    }

    const mytheme =  createTheme({
    });


    return (
        <Container className="inhalt" sx={{paddingBottom:'20px'}}>
            <ThemeProvider theme={mytheme}>
                <MaterialTable 
                    title="Mitarbeiter"
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} von {count}',
                            labelRowsPerPage: 'Zeilen pro Seite',
                            labelRowsSelect : 'Zeilen'
                        },
                        toolbar: {
                            nRowsSelected: '{0} Reihen ausgewählt',
                            searchPlaceholder: 'Suche'
                        },
                        header: {
                            actions: 'Aktionen'
                        },
                        
                        body: {
                            emptyDataSourceMessage: 'Keine Daten zum Anzeigen',
                            editRow : {
                                deleteText : 'Sicher löschen?'
                            }
                        }
                    }}
                    columns={[
                        { title: 'Name', field: 'username',editable: 'never' },
                        { title: 'Vollzeitäquivalent', field:'vz', type:'numeric'},
                        { title: 'Budget', field:'shopbudget',type:'numeric' },
                        { title: 'Aktiv', field:'active',type:'boolean' },
                    ]}
                    options={{
                        padding: 'dense',
                        search : true,
                        pageSize: 20,
                    }}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                        axios.post('mitarbeiterupdate',{old:oldData,new:newData}).then(result => {
                            setMaliste(result.data)
                        })    
                        

                    }}
                    data={maliste}
                />
            </ThemeProvider>
        </Container>
    )
}


function Rohdaten() {
    const [axios,setAxios] = useGlobal('axios')
    const [bestellungen,setBestellungen] = useState([])
    const [editme,setEditme] = useState([])
    const [fulluserlist,setFulluserlist] = useState([])
    

    useEffect(() => {
        getData()
        axios.get('fulluserlist').then(response => {
            setFulluserlist([...response.data,'POOL'])
        })
    },[])

    const getData = () => {
        axios.get('rohdatenbestellungen').then(response => setBestellungen(response.data))
    }


    const mytheme =  createTheme({
    });

    const changeUser = (row,newuser) => {
        if (!!newuser) {
            const params = {bestellung:row,newuser:newuser}
            axios.post('changeuserrohdaten',params).then(response => {
                Swal.fire('',response.data.msg)
                getData()}
            )
        }
        setEditme(false)
    }

    const textstatus = ['bestellt','in Lieferung','zur Abholung','abgeholt']

    return (
        <Container className="inhalt" sx={{paddingBottom:'20px'}}>
            <ThemeProvider theme={mytheme}>
                <MaterialTable 
                    title="Mitarbeiter"
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} von {count}',
                            labelRowsPerPage: 'Zeilen pro Seite',
                            labelRowsSelect : 'Zeilen'
                        },
                        toolbar: {
                            nRowsSelected: '{0} Reihen ausgewählt',
                            searchPlaceholder: 'Suche'
                        },
                        header: {
                            actions: 'Aktionen'
                        },
                        
                        body: {
                            emptyDataSourceMessage: 'Keine Daten zum Anzeigen',
                            editRow : {
                                deleteText : 'Sicher löschen?'
                            }
                        }
                    }}
                    columns={[
                        { title: 'Bestelldatum', field: 'bestelldatum',type:'datetime' },
                        { title: 'Produkt', field: 'produkt', },
                        { title: 'Variante', field: 'variante', render: rowData => rowData.variante ? rowData.variante : '' },
                        {title: 'Status',field: 'status', render: rowData => textstatus[rowData.status]},
                        { title: 'AbholKey', field:'abholkey' },
                        { title: 'Mitarbeiter', field:'user', render:
                            rowData => rowData._id == editme ? 
                            <Autocomplete options={fulluserlist} value={rowData.user} style={{width:'200px'}} 
                            renderInput={(params) => <TextField {...params} label="stattdessen" style={{width:'100%'}}/>}
                            onChange={(e,n) => changeUser(rowData,n)} />
                            :
                            <Box sx={{width:'200px', display:'flex',justifyContent:'space-between'}}>{rowData.user}<Edit onClick={e => setEditme(rowData._id)} /></Box>

                        }
                    ]}
                    options={{
                        padding: 'dense',
                        search : true,
                        pageSize: 20,
                    }}
                    editable={{
                        onRowDelete: (oldData) =>
                        axios.post('bestellungloeschen',{_id:oldData._id}).then(result => {
                            if (!!result.data.msg){
                                Swal.fire('',result.data.msg)
                            }
                            getData()
                        })

                    }}
                    detailPanel={[
                        {
                          tooltip: 'Details',
                          render: rowData => {
                            return (
                              <Card>
                                <CardContent>
                                Bestellt: {rowData.bestelldatum} 
                                {rowData['bestellt von'] && <Box>Bestellt von: {rowData['bestellt von']}</Box>}
                                {rowData.bbh && <Box>Bestellt beim Händler: {rowData.bbh} von {rowData['bbh von']}</Box>}
                                {rowData.evh && <Box>Waren-Eingang: {rowData.evh} durch {rowData['evh durch']}</Box>}
                                {rowData.abholdatum && <Box>Abgeholt: {rowData.abholdatum} durch {rowData.abholer} {rowData.abholer !== rowData.user ? '('+rowData.altkey?.abholkey+')': ''}</Box>}
                                {rowData.changes && rowData.changes.map((r,i) => <Box key={'bkey_'+i}>{r}</Box>)}
                                </CardContent>
                              </Card>
                            )
                          },
                        }]}
                    data={bestellungen}
                />
            </ThemeProvider>
        </Container>
    )
}




function Verwaltung() {
    const [axios,setAxios] = useGlobal('axios')
    const [einstellungen,setEinstellungen] = useState({'active':true,'balkennachricht':''})
    
    useEffect(() => {
        geteinstellungen()
    },[])

    const geteinstellungen = () => {
        axios.get('einstellungen').then(result => {
            console.log('GET',result)
            setEinstellungen(result.data)
        })
    }

    const updateeinstellungen = (quickmod) => {
        const params = {...einstellungen,...quickmod}
        axios.post('einstellungen',params).then(result => {
            console.log('POST',result)
            setEinstellungen(result.data)
        })
    }
 

    return (
        <Container className="inhalt" sx={{paddingBottom:'20px'}}>
                <Typography variant="h5">Einstellungen</Typography>
                <Box>
                    <FormControlLabel control={<Checkbox  checked={!!einstellungen.active}
                            onChange={e => updateeinstellungen({active:e.target.checked})}                    
                    />} 
                        label='Shop aktiv'
                    />
                </Box>
                <Box sx={{display:'flex',justifyContent:'flex-start'}}>
                <TextField fullWidth label="Balkennachricht für alle" value={einstellungen.balkennachricht} onChange={e => setEinstellungen({...einstellungen,balkennachricht:e.target.value})}/>
                    <Button variant="outlined" onClick={e => updateeinstellungen({})}>speichern</Button>                    
                </Box>

        </Container>
    )

}