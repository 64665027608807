import { Box, Button, Chip, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Stack, Switch, TextField, Typography } from "@mui/material";
import useGlobal from "./useGlobal";
import { useEffect, useState } from "react";
import './shop.css'
import { isMobile } from "react-device-detect";
import { AddBox, Close, Delete, Edit, HdrPlus, Height, PlusOne } from "@mui/icons-material";
import { UpdateItem } from "./updateItem";



export function Shop(){
    const [axios,setAxios] = useGlobal('axios')
    const [loggedin,setLoggedin] = useGlobal('loggedin')
    const [cats,setCats] = useState([])
    const [foruser,setForuser] = useGlobal('foruser',false)
    const [catfilter,setCatfilter] = useState([])
    const [budgetfilter,setBudgetfilter] = useState(false)
    const [items,setItems] = useState([])
    const [cart,setCart] = useGlobal('cart')
    const [curbudget,setCurbudget] = useGlobal('curbudget',0)
    const [addto,setAddto] = useState(false)
    const [fullviewitem,setFullviewitem] = useState(false)
    const [permissions,setPermissions] = useGlobal('permissions',[])
    const [editItem,setEditItem] = useState(false)

    useEffect(() => {
        if (axios && loggedin) {
            console.log('get Items')
            getData()
        }
    },[axios,loggedin,foruser])

    const getData = () => {
        const params = {foruser:foruser}
        axios.get('shop',{params}).then(data => {
            console.log(data)
            setItems(data.data.data)
            if (editItem) {
                setEditItem(data.data.data.find( item => item._id == editItem._id))
            }                
        })
        axios.get('cats').then(data => {setCats(data.data)})
    }


    const orderitem = (item) => {
        setFullviewitem(false)
        setAddto(false)
        if (item){
            let count = item?.count || 1
            let tmpcart = cart
            for (let i = 0; i < count; i++) {
                    tmpcart = [...tmpcart,item]
            }
            setCart(tmpcart)
        }
    }

    const afterupdate = (val) => {
        setEditItem(false)
        if (val) {
            getData()
        }
    }

 

    return (
        <Container fixed maxWidth='lg' className='main'>
        <Box sx={{width:'100%',marginTop:'10px'}}>
            {!!(cats && cats.length) && 
                <Stack direction="row" spacing={1} flexWrap="wrap" justifyContent="space-evenly">
                    {cats.map((c,i) => 
                        <Chip label={c} clickable variant={catfilter.includes(c) ? 'contained' : 'outlined'}
                            onClick={e => setCatfilter([...new Set([...catfilter,c])])}
                            onDelete={e => setCatfilter(catfilter.filter(i => i !== c))}
                            deleteIcon={catfilter.includes(c) ? <Close /> : <></>}
                        />
                    )}
        <FormControlLabel control={<Switch checked={budgetfilter} onChange={e => setBudgetfilter(e.target.checked)} />} label="Nur, was ich mir leisten kann" />
                </Stack>
            }
        </Box>
        <Divider/>
            {permissions.includes('shopmanager') && 
            <Paper className={isMobile ? 'item_mobile' : 'item'} style={{justifyContent:'center',alignItems:'center'}} >
                <AddBox style={{fontSize: 60}} onClick={e => setEditItem({name:'',beschreibung:'',varianten:'',preis:0})}/>
            </Paper>
            }
            {!!items && items.filter(it => (it.Preis <= curbudget) || !budgetfilter).filter(it => catfilter.includes(it?.kategorie) || !catfilter.length).map(item =>         
        <Paper className={isMobile ? 'item_mobile' : 'item'} sx={{filter:item.deaktiviert ? 'brightness(0.5)': 'brightness(1)' }}>
            {item.name}
            <img src={'https://shop.rdheinsberg.de/api/bild/small_'+item.bild} onClick={e => setFullviewitem(item)}
            alt='bild folgt' className={isMobile ? 'img_mobile' : 'img_browser'}/>
            <Button variant="contained" sx={isMobile ? {padding:'2px'} : {padding:'5px'}} onClick={e => setAddto(item)} disabled={(item.Preis > curbudget) || item.deaktiviert }>
                <Box className='bestellbuttonmitpreis'>{item?.Preis && <Box className='preis'>{parseFloat(item.Preis).toFixed(2)}€</Box>}    <Box>bestellen</Box></Box>
                
            </Button>

        </Paper>
        
        )}
        <FullView item={fullviewitem} bookme={e => orderitem(e)} curbudget={curbudget} editme={setEditItem}/>
        <VariantChooser item={addto} onchoose={e => orderitem(e)}/>
        {permissions.includes('shopmanager') && <UpdateItem closeme={e => afterupdate(e)} item={editItem} />}
        </Container>
    )
}


function FullView({item,bookme,editme,curbudget}) {
    const [variante,setVariante] = useState(false)
    const [permissions,setPermissions] = useGlobal('permissions',[])
    const [count,setCount] = useState(1)


    useEffect(() => {
        if (!item) {
            setVariante(false)
            setCount(1)
        }
        if (item) console.log(item?.varianten,variante,!!item?.varianten.length)
    },[item,variante])


    return (
        <Dialog open={item} onClose={e => bookme(false)}>
            {item && <>
            <DialogTitle>{item.name}</DialogTitle>
            <DialogContent>
            <img src={'https://shop.rdheinsberg.de/api/bild/big_'+item.bild} alt='bild folgt' style={{width:'90%',height:'auto'}}/>
            {permissions.includes('shopmanager') && 
                <Edit onClick={e => editme(item)}/>
            }
            <Container>
                {item.beschreibung}
            </Container>
            </DialogContent>
            <DialogActions>
                {!!(item?.varianten && item.varianten.length) && 
                <FormControl fullWidth sx={{margin:'5px'}} >
                    <InputLabel>Variante</InputLabel>
                    <Select value={variante} label="Variante" onChange={e => setVariante(e.target.value)}>
                        {item.varianten.map((v,i) => 
                            <MenuItem value={v}>{v}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                }
                {permissions.includes('shopmanager') && 
                    <TextField type="number" value={count} onChange={e => setCount(e.target.value)} label='Anzahl'/>
                }                
                <Button variant="outlined" onClick={e => bookme(false)}>abbrechen</Button>
                <Button variant="contained" onClick={e => bookme({...item,variante:variante,count:count})} 
                        disabled={!((item?.varianten.length && variante) || !item?.varianten.length) || (item.Preis > curbudget && !permissions.includes('shopmanager'))}> bestellen</Button>


            </DialogActions>

            </>}

        </Dialog>
    )
}

function VariantChooser({item,onchoose}) {
    const [selvar,setSelvar] = useState(false)
    const [open,setOpen] = useState(false)

    useEffect(() => {
        if (!item) {
            console.log('noitem')
            setOpen(false)
            setSelvar(false)
        } else {
            console.log(item)
            if (item && item?.varianten && item.varianten.length) {
                setOpen(true)
            } else {
                onchoose(item)
            }
        }
    },[item])

    

    return (
        <Dialog open={open} PaperProps={{style:{padding:'20px'},className:'userinfodialog'}}>
            <DialogTitle>{item.name}</DialogTitle>
            <DialogContent>
                <FormControl>
                    <FormLabel>Welche Variante?</FormLabel>
                    <RadioGroup value={selvar} onChange={e => setSelvar(e.target.value)}>
                        {item && item.varianten && item.varianten.map((v,i) => 
                            <FormControlLabel value={v} control={<Radio/>} label={v}/>                        
                        )}
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={e => onchoose(false)}>abbrechen</Button>
                <Button variant="contained" disabled={!selvar} onClick={e => onchoose({...item,variante:selvar})}>bestellen</Button>
            </DialogActions>

        </Dialog>
    )
    

}